.light {
  background: #ffffff;
  color: #000000; }

.dark {
  background: #282828;
  color: #ffffff; }

.icon {
  margin: 0 1em; }

.icon svg {
  height: 1.1em;
  width: 1.1em;
  fill: currentColor;
  vertical-align: text-bottom; }

.archive div {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline; }

.archive h3 {
  margin: 0.2em 0; }

.archive h3, .archive a {
  font-weight: inherit; }

.archive time {
  min-width: 10em; }

.large img, .large.embed {
  max-width: 140%;
  margin-right: -20%;
  margin-left: -20%; }

.embed {
  position: relative;
  padding-bottom: 56.25%;
  margin: 1.5em 0; }

.embed.large {
  padding-bottom: 78.75%; }

.embed iframe {
  position: absolute;
  width: 100%;
  height: 100%; }
